/* eslint-disable no-param-reassign, custom-linting/use-saddlebag-browser */

/* eslint no-unused-expressions: off */

/* There is a bug in eslint for dynamic imports. Please check https://github.com/typescript-eslint/typescript-eslint/issues/2108
We have 2 options, either to use .then() for the dynamic import or disable no-unused-expressions for entire file.  */
((window) => {
  window.acorn = window.acorn || {};
  window.acorn.contextPromise = new Promise((resolve, reject) => {
    const path = `path=${window.location.pathname}`;
    const query = window.location.search ? `${window.location.search}&${path}` : `?${path}`;

    const fetchContext = (retries: number) => {
      const xhr = new XMLHttpRequest();
      const url = `/g/banana/api/context${query}`;

      xhr.open('GET', url);

      const eventHandler = (event: Event) => {
        if (event.type === 'load') {
          if (xhr.status === 200) {
            resolve(xhr.responseText);
          }

          // As in handleBotTraffic.js perimeterX will provide a URL to redirect to. We don't want to retry the request here.
          if (xhr.status === 403) {
            const response = JSON.parse(xhr.responseText);

            if (response.redirect_to) {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({
                reason: 'blocked suspected bot with redirect_to',
                redirectTo: response.redirect_to,
              });
            }
          } else if (xhr.status >= 400) {
            const message = `XHR Error Type: ${event.type}; status: ${xhr.status}; responseText: ${xhr.responseText}; readyState: ${xhr.readyState}; url: ${url}`;

            reject(message);
          }
        } else if (retries) {
          fetchContext(retries - 1);
        } else {
          const message = `XHR Error Type: ${event.type}; status: ${xhr.status}; responseText: ${xhr.responseText}; readyState: ${xhr.readyState}; url: ${url}`;
          reject(message);
        }
      };

      xhr.onload = eventHandler;
      xhr.onabort = eventHandler;
      xhr.onerror = eventHandler;
      xhr.ontimeout = eventHandler;
      xhr.send();
    };

    fetchContext(2);
  });

  // In production all uses of `translate()` have been replaced with strings in the build step when producing single locale bundles. In development where we have one single bundle we use the local translate function to read from local string maps.
  // See translations.md
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line global-require
    window.translate = require('./utils/translate.local').translate;
  }
})(window);

import(
  /* webpackChunkName: "Bootstrap" */
  './bootstrap'
)
  .then((bootstrap) => bootstrap.run())
  .catch((error) => {
    throw error;
  });

export {};
